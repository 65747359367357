import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../assets/descaling.sass"
import Image from "../../components/image"
import Carousel from 'react-bootstrap/Carousel'

const CustomPieces = () => (
  <div className="descaling-component">
    
    <SEO title="Custom Pieces" />

    <div className="service-header">
      <h1>Custom Pieces</h1>
    </div>

    <div className="descaling-statements descaling-statements-red">
      <p>
        Unleash your imagination with our custom piece crafting service, where your unique ideas come to life. From bespoke furniture designs 
        and personalized woodworking projects to tailor-made clothing and one-of-a-kind metalwork sculptures, 
        our artisans blend skill and creativity to create items that truly reflect your personal style.
        Experience the fusion of artistry and craftsmanship in every custom piece we create, tailor-made just for you.
      </p>
    </div>

    <div className="desktop-image-video">

      <Carousel>
          <Carousel.Item interval={1500}>
            <div className="scoping-images">
              <Image filename="park.png"/>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <div className="scoping-images">
              <Image filename="shed.jpg"/>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <div className="scoping-images">
              <Image filename="lights.jpg"/>
            </div>
          </Carousel.Item>
  
      </Carousel>

    </div>
    
    <div className="descaling-statements desktop-statements">    
      <ul className="sewer-services-list">
        <li>Custom Furniture Design and Crafting</li>
        <li>Personalized Woodworking Projects</li>
        <li>Custom Tailored Clothing</li>
        <li>Unique Metalwork and Sculptures</li>
      </ul>
    </div>

  </div>
)

export default CustomPieces
